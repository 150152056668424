<template>
  <div>
    <div class="publishFC">
      <div class="main">
        <div class="content">
          <span class="content-title">图片文字描述: </span>
          <textarea
            placeholder="可以描述图片的主体,背景色,图片风格,图片尺寸,人物表情,特写镜头等。中英文皆可,最多300个字。"
            style="
              border: 1px dashed black;
              background-color: transparent;
              padding: 20px;
            "
            :maxLength="300"
            v-model="c_content"
          ></textarea>
        </div>

        <!-- <div class="upload_img-module">
          <input
            type="file"
            class="file_input"
            multiple
            id="fileInput"
            @change="uploadImage"
          />
          <label class="upload_btn" for="fileInput">上传目标效果图</label>
          <div class="img-list">
            <div class="img-item" v-for="(item, index) in uploaded_img_list">
              <img
                class="img-as"
                :src="base_url + item.filename"
                alt="Image Preview"
                :key="index"
                :class="getImgClassName(index)"
              />
              <div class="img-close-btn" @click="close_img($event, index)">
                <i class="el-icon-close"></i>
              </div>
            </div>
          </div>
        </div> -->

        <div class="interact">
          <a
            href="/community"
            class="publish-btn"
            @click.prevent="publish_circle($event)"
            >提交</a
          >
        </div>

        <!-- 举例模块 -->
        <div class="example">
          <h1>举个例子：</h1>
          <div class="text">
            <span>图片文字描述: </span>
            <p>
              一个年轻的女孩,穿着一件颜色鲜艳的齐肩毛衣，站在报纸墙前，左手扶着右手，右手放在下巴上。她有点深思熟虑，时尚杂志动漫风格,图片尺寸1:1
            </p>
          </div>
          <div class="example_img">
            <span style="display: block">生成的图片效果:</span>
            <div
              class="img_list"
              v-for="(item, index) in img_list"
              :key="index"
            >
              <img v-lazy="item" alt="" />
            </div>
          </div>
        </div>
        <!-- <DividerLineWithTitle class="divide-line"></DividerLineWithTitle> -->
      </div>

      <!-- 展示用户提交的求图信息模块 -->
      <div class="others_map">
        <div class="title">
          <h4>快来看看小伙伴们的提交的求图需求吧</h4>
        </div>
        <article
          data-v-bb5f5e3e=""
          class="blog-list-box"
          v-for="item in submit_data_list"
        >
          <a
            href="https://blog.csdn.net/CsDn_en/article/details/136027823"
            target="_blank"
            data-report-click='{"spm":"3001.5502"}'
            data-report-query="spm=3001.5502"
            ><div class="blog-img-box">
              <img
                :src="item.request_result_img_url_list"
                alt=""
                class="course-img"
              />
            </div>
            <div class="list-box-cont">
              <div>
                <div class="blog-list-content">
                  {{ item.content }}
                </div>
              </div>
              <div class="blog-list-footer">
                <div class="blog-list-footer-left">
                  <div class="view-time-box">
                    发布求图&nbsp;{{ item.create_time }}&nbsp;·
                  </div>
                  <!-- <div class="view-num-box">
                    <span class="view-num"
                      >{{ item.read
                      }}<span class="two-px">&nbsp;阅读&nbsp;·</span></span
                    >
                  </div>
                  <div class="give-like-box">
                    <span class="give-like-num"
                      >{{ item.dianzan
                      }}<span class="two-px">&nbsp;点赞&nbsp;·</span></span
                    >
                  </div>
                  <div class="comment-box">
                    <span class="comment-num"
                      >{{ item.rate
                      }}<span class="two-px">&nbsp;评论&nbsp;·</span></span
                    >
                  </div>
                  <div class="comment-box">
                    <span class="comment-num"
                      >{{ item.collect
                      }}<span class="two-px">&nbsp;收藏</span></span
                    >
                  </div> -->
                </div>
              </div>
            </div></a
          >
        </article>
        <!-- <article data-v-bb5f5e3e="" class="blog-list-box">
          <a
            href="https://blog.csdn.net/CsDn_en/article/details/136027823"
            target="_blank"
            data-report-click='{"spm":"3001.5502"}'
            data-report-query="spm=3001.5502"
            ><div class="blog-img-box">
              <img
                src="http://image.soutudashi.com/default/qiutu_example_f7c07f5b.png"
                alt=""
                class="course-img"
              />
            </div>
            <div class="list-box-cont">
              <div>
                <div class="blog-list-content">
                  一个年轻的女孩,穿着一件颜色鲜艳的齐肩毛衣，站在报纸墙前，左手扶着右手，右手放在下巴上。她有点深思熟虑，时尚杂志动漫风格,图片尺寸1:1
                  肯定会更加韩国代购很久很久风格和发动机可见光和水果和手机导购涉及三个函数递归可是根深蒂固事件过后就是更何况就是感觉还是随时都会深度监控还是决定更换手机导购翻身的机会手机打开说的话公司大概收到贵司的世界的繁华世界的工行卡大概是觉得根深蒂固
                </div>
              </div>
              <div class="blog-list-footer">
                <div class="blog-list-footer-left">
                  <div class="view-time-box">
                    发布博客&nbsp;前天 15:05&nbsp;·
                  </div>
                  <div class="view-num-box">
                    <span class="view-num"
                      >352<span class="two-px">&nbsp;阅读&nbsp;·</span></span
                    >
                  </div>
                  <div class="give-like-box">
                    <span class="give-like-num"
                      >4<span class="two-px">&nbsp;点赞&nbsp;·</span></span
                    >
                  </div>
                  <div class="comment-box">
                    <span class="comment-num"
                      >0<span class="two-px">&nbsp;评论&nbsp;·</span></span
                    >
                  </div>
                  <div class="comment-box">
                    <span class="comment-num"
                      >8<span class="two-px">&nbsp;收藏</span></span
                    >
                  </div>
                </div>
              </div>
            </div></a
          >
        </article> -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="10"
          layout="prev, pager, next"
          :total="total"
          background
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
var AliOss = require("ali-oss");
import { getUserInfo, get_token } from "../common/common.js";
import { Loading } from "element-ui";
import CONSTANT from "../config/constant.js";
// import DividerLineWithTitle from "../components/DividerLineWithTitle";
export default {
  data() {
    return {
      uploaded_img_list: [],
      c_content: "",
      img_list: [
        CONSTANT.GET_IMAGE_ADDRESS +
          encodeURIComponent("default/qiutu_example30edb634.png"),
        CONSTANT.GET_IMAGE_ADDRESS +
          encodeURIComponent("default/qiutu_example_f7c07f5b.png"),
      ],
      total: 30,
      currentPage: 1,
      submit_data_list: [],
      loadingInstance: null,
    };
  },
  methods: {
    get_cur_user() {
      // console.log("ddsgdg : ", getUserInfo(), this.cur_circle_data);
      return getUserInfo();
    },

    getImgClassName(index) {
      if (index <= 5) {
        return "first-line";
      } else {
        return "last-line";
      }
    },

    //点击关闭图片
    close_img(e, index) {
      // console.log("删除图片： ", e, e.target.parentNode.parentNode, index);
      if (index > -1 && index < this.uploaded_img_list.length) {
        // console.log("删除前: ", this.uploaded_img_list);
        this.uploaded_img_list.splice(index, 1);
        // console.log("删除后: ", this.uploaded_img_list);
      }
    },

    message_box(type, message) {
      this.$message({
        showClose: true,
        message: message,
        type: type,
        customClass: "messageIndex",
      });
    },

    handleSizeChange(val) {
      this.pageSize = val; // 改变每页显示的数量
      this.currentPage = 1; // 重置当前页码
      // this.fetchData(); // 获取数据
    },
    handleCurrentChange(val) {
      // console.log("handlecurrentchange: ", val);
      this.currentPage = val; // 改变当前页码
      // console.log("当前页面： ", this.currentPage);
      this.get_qiutu_list(this.currentPage); // 获取数据
    },
    async get_qiutu_list(page_num) {
      // console.log("调用求图list接口", page_num);
      // 开启loading加载效果
      this.loadingInstance = Loading.service({ fullscreen: true });

      // console.log("执行加载loading");
      try {
        var response;
        if (page_num == 1) {
          response = await this.$http.get(
            "http://www.soutudashi.com/get_qiutu_list",
            {
              headers: { "Content-Type": "application/json" },
              // data,
              // data,
            }
          );
        } else {
          response = await this.$http.get(
            `http://www.soutudashi.com/get_qiutu_list?page_num=${page_num}`,
            {
              headers: { "Content-Type": "application/json" },
              page_num: page_num,
              // data,
              // data,
            }
          );
        }

        // if (response.data.main.length == 0) {
        //   this.message_box("error", "该用户还没有在本站注册，请先注册");
        // } else {
        // console.log("求图接口", response);

        // console.log("dddddddddsdfhdfhdf: ", response.data);

        this.total = response.data.total;
        this.submit_data_list = response.data.data;

        return response.data.data;
        // }
      } catch (e) {
        console.log(e);
      } finally {
        // console.log("取消执行加载loading");
        // 清除loading加载效果
        this.loadingInstance.close(); // this.c_content = "";
        // this.$router.push("/qiutu");
        // this.comment_content = "";
      }
    },
    // 发布朋友圈
    async publish_circle(event) {
      var pic_list = [];
      for (let i = 0; i < this.uploaded_img_list.length; i++) {
        pic_list.push(
          CONSTANT.OSS_BASE_URL + this.uploaded_img_list[i]["filename"]
        );
      }

      const data = {
        user_name: this.get_cur_user().user_name,
        text_define: this.c_content,
      };

      // console.log("发布朋友圈前: ", data);

      // 控制上传的内容中有图片或文字描述
      if (
        this.uploaded_img_list.length <= 0 &&
        this.c_content.trim().length <= 0
      ) {
        this.message_box("error", "请输入求图内容噢");
        // event.stopPropagation();
        return;
      }
      try {
        const token = get_token("token");
        var response = await this.$http({
          method: "post",
          url: "http://www.soutudashi.com/submit_qiutu_request", // 拼接URL和参数
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", // 设置跨域参数
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${token}`,
          },
          data: data,
          responseType: "json",
        });

        // var response = await this.$http.post(
        //   "http://www.soutudashi.com/submit_qiutu_request",
        //   {
        //     headers: { "Content-Type": "application/json" },
        //     data,
        //     // data,
        //   }
        // );

        // if (response.data.main.length == 0) {
        //   this.message_box("error", "该用户还没有在本站注册，请先注册");
        // } else {
        // console.log("求图接口", response);

        // console.log("dddddddddsdfhdfhdf: ", response.data);

        // const res = response.data;
        // this.cur_circle_data.children.unshift({
        //   comment_id: res.comment_id,
        //   target_id: res.target_id,
        //   content: res.content,
        //   publish_time: res.create_time,
        //   nick_name: res.nick_name,
        //   avatar_url: res.avatar_url,
        //   child_comment_cnt: res.child_comment_cnt,
        //   like_cnt: res.like_cnt,
        //   is_like: res.is_like,
        // });
        // this.total = response.data;
        return response.data;
        // }
      } catch (e) {
        console.log(e);
      } finally {
        this.c_content = "";
        // this.$router.push("/qiutu");
        window.location.reload();
        // this.comment_content = "";
      }
    },
  },

  mounted() {
    this.get_qiutu_list(1);
    window.scrollTo(0, 0);
  },
  // components: { DividerLineWithTitle },
  created() {},
};
</script>
<style scoped>
.publishFC {
  width: 100%;
  height: 100%;
  /* background-color: red; */
}

.bg {
  position: relative;
  width: 600px;
  /* height: 600px; */
  /* background-color: black; */
  min-height: 500px;
  margin: 0px auto;
}

.publishFC > .main {
  position: relative;
  width: 1120px;
  margin: 20px auto;
}

.publishFC > .main > .content {
  width: 1120px;
  margin: 20px auto;

  text-align: left;
}

.publishFC > .main > .interact {
  width: 1120px;
  margin: 20px auto;
}
.publishFC > .main > .content > .content-title {
  font-size: 22px;
  position: relative;
  display: block;
  margin-bottom: 10px;
}

.publishFC > .main > .content > textarea {
  min-height: 100px;
  width: 1118px;
  position: relative;
  /* margin: 0px auto; */
}

.divide-line {
  margin-top: 20px;
}
h1 {
  font-size: 20px;
}
.example {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  line-height: 2;
  border-radius: 5px;
  border: 1px black solid;
  padding: 10px 20px;
}
/* .upload_img-module {
  margin: 20px auto 20px auto;
  width: 1120px;
} */

.img_list {
  display: inline-block;
}
.example_img > .img_list > img {
  width: 300px;
  margin-right: 10px;
  /* display: inline-block; */
}

/* .img-list {
  margin-top: 20px;
  width: auto;
  min-height: 180px;
  border: 1px dashed black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
} */

/* input[type="file"] {
  display: none;
} */

/* input[type="file"] + label {
  background-color: green;
  padding: 10px 20px;
  width: 110px;
  height: 60px;
  border: 1px solid #ccc;
  cursor: pointer;
  box-sizing: border-box;
} */

/* .upload_btn {
  display: block;
  border-radius: 20px;
  color: white;
} */

/* .upload_btn:hover {
  scale: 1.1;
} */

/* .img-list img {
  width: 200px;
  height: 150px;
} */

/* .img-list img.first-line {
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
} */

/* .img-list img.last-line {
  margin-left: 20px;
  margin-bottom: 20px;
} */

/* .img-list .img-item {
  position: relative;
} */
.problem-describe {
  /* display: flex; */
  width: 100%;
}

.problem-describe span {
  float: left;
  display: block;
}

.problem-area {
  width: 100%;
  margin-top: 20px;
  border: 1px dashed black;
}
.submit_btn {
  margin: 20px auto;
  background-color: green;
  /* padding: 10px 20px; */
  width: 100px;
  height: 45px;
  border: 1px solid #ccc;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 18px;
}

.submit_btn:hover {
  scale: 1.1;
}

/* .img-close-btn {
  position: absolute;
  right: 0px;
  top: 20px;
  width: 25px;
  height: 25px;
} */

/* .img-close-btn i {
  width: 100%;
  height: 100%;
  font-size: 25px;
  line-height: 25px;
} */

/* .img-close-btn i:hover {
  transform: rotate(180deg);
  transition: transform 0.5s;
} */

.interact > .publish-btn {
  /* display: block; */
  width: 120px;
  height: 60px;
  background-color: pink;
  display: block;
  border-radius: 10px;
  text-decoration-line: none;
  line-height: 60px;
}

.list-box-cont {
  display: flex;
  flex-direction: column;
}

.list-box-cont {
}
.blog-list-box {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  margin: 0 24px;
  border-bottom: 1px solid #f0f0f2;
  width: 1120px;
  margin: 0px auto;
}

.blog-list-box .blog-img-box {
  position: relative;
  margin-right: 16px;
  /* display: flex; */
  text-align: left;
  height: 100px;
  width: 100px;
}

.blog-img-box > img {
  width: 100px;
  height: 100px;
}
.blog-list-footer-left {
  display: flex;
  justify-content: flex-start;
}

.blog-list-footer-left > div:not(:first-child) {
  margin-left: 20px;
}
.blog-list-box > a {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-top: 20px;
  /* border-top: 1px solid rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(0, 0, 0, 0.5); */
}

.blog-list-box > a:link,
a:visited {
  color: inherit;
  text-decoration: none;
}

.blog-list-content {
  text-align: left;
  height: 70px;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.others_map {
  line-height: 2;
}

.others_map > .title {
  text-align: left;
  padding: 24px 0 0 0;
  /* margin: 0 24px; */
  /* border-bottom: 1px solid #f0f0f2; */
  width: 1120px;
  margin: 0px auto;
  border-bottom: 2px dashed black;
}

.messageIndex {
  z-index: 999999 !important;
}
</style>
